import "./App.css";
import ProjectPage from "./pages/ProjectPage";

function App() {
  return (
    <>
      <ProjectPage />
    </>
  );
}

export default App;
